/*****************************
	Countdown
*****************************/
.countdown {
	display: inline-block;
	margin-right: 40px;

	span {
		font-size: 80px;
		line-height: 80px;
		font-weight: 600;
		color: $primary;
	}

	p {
		font-size: 20px;
		text-transform: capitalize;
		margin-bottom: 0;
		color: $gray-8;
	}

}

.coming-soon {
	 	background-position: center center;
		.facebook-bg {
		    background-color: #445c8e;
		}
		.twitter-bg {
		    background-color: #43afe9;
		}
		.google-bg {
		    background-color: #dc0510;
		}
		.linkedin-bg {
		    background-color: #13799f;
		}
		.social-bg-hover {
			position: relative;
			color: $white;
			span {
				position: relative;
				color: $white;
			}
			&:before {
				content: "";
				color: $white;
				width: 100%;
				height: 100%;
				display: inline-block;
				position: absolute;
				left: 0;
				top: 0;
			}
			&:hover {
				color: $white;
				&:before {
					background-color: rgba($black, 0.1);
				}
			}
		}
		.newsletter {
			.form-control {
				padding-right: 150px;
			}
		}

		.newsletter {
			.btn {
				position: absolute;
				top: 0;
				right: 0;
				padding: 13px 30px;
			}
		}

}
