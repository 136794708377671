// Font family
$font-base: 		'Poppins', sans-serif;
$font-heading:  'Poppins', sans-serif;

// Colors
$body-color:		#646f79;
$primary:				#21c87a;
$white:					#ffffff;


$gray-1: 				#f6f6f6; 	// bg light
$gray-2: 				#dfdfdf;
$gray-3: 				#646f79; 	// Boday text
$gray-4: 				#626262;	// lable color
$gray-5: 				#3a4957;
$gray-6: 				#293745;
$gray-7: 				#1d2936;
$gray-8: 				#212529; 	// Hedding color
$gray-9: 				#212529; 	// bg color

$black:  				#000000;

$green:  				#53b427;
$red:  				 	#e74c3c;
$blue:					#186fc9;
$orange:				#ffc107;

// Border color
$border-color: 	#eeeeee;
$border-color-2: 	#21c87a;

// border radius
$border-radius: 3px;

// Box shadow
$box-shadow: 0px 10px 15px 0px rgba($black , 0.05);
